<ng-container *transloco="let t; read: 'nucleus.dialogs.unsaved-changes-process-dialog'">
  <hy-dialog
    #dialog
    header="{{ t('header') }}"
    message="{{ t('dialog-message') }}"
    confirmLabel="{{ t('confirm-button-label') }}"
    dismissLabel="{{ t('dismiss-button-label') }}"
    (confirmed)="dialog.close(true)"
    (dismissed)="dialog.close(false)"
    [destructive]="true"
  ></hy-dialog>
</ng-container>
