import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, switchMap } from 'rxjs';
import { FeatureFlagResponse, FlagAdditionalContext, FlagContext, Flags } from '../../models/feature-flag';
import { FeatureFlagStrategy } from '../feature-flag.strategy';
import { FeatureFlagsContextBuilderService } from '../feature-flags-context-builder/feature-flags-context-builder.service';

@Injectable()
export class FeatureFlagStrategyServer extends FeatureFlagStrategy {
  constructor(private readonly _http: HttpClient, private readonly _flagsContextBuilder: FeatureFlagsContextBuilderService) {
    super();
  }

  getFlag(flagKey: string, context?: FlagAdditionalContext) {
    return this._flagsContextBuilder
      .getFlagContext(context)
      .pipe(switchMap((context) => this._getFlagValueBasedOnContext(flagKey, context)));
  }

  getAllFlags(flags: string[]) {
    return this._flagsContextBuilder
      .getFlagContext()
      .pipe(switchMap((context) => this._http.post<Flags>('/feature-flag/all', { context, flags })));
  }

  private _getFlagValueBasedOnContext(flagKey: string, context: FlagContext) {
    return this._http
      .post<FeatureFlagResponse>('/feature-flag', { flagKey, context })
      .pipe(catchError(() => of({ error: 'request failed' })));
  }
}
