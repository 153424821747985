import { ApolloError, isApolloError } from '@apollo/client/core';
import { Error as HxpError } from '@hxp/graphql';
import { EMPTY, Observable, catchError, map, throwError } from 'rxjs';

export const NOT_FOUND_ERROR_MESSAGE = 'NOT_FOUND';

const isNotFoundError = (error: Error | ApolloError): boolean => {
  const apollo404error = isApolloError(error) && error.graphQLErrors[0].extensions.code === '404';
  const notFound = error.message === HxpError.Notfound || error.message === NOT_FOUND_ERROR_MESSAGE;

  return apollo404error || notFound;
};

interface NonFoundValueHandlerOptions {
  /**
   * If true, the error will be thrown for additional processing in catchError methods.
   *
   * Otherwise it will be silenced when the shouldRedirectToNotFoundPage returns true,
   *
   * @default false
   */
  readonly continueOnErrorCatch: boolean;
}

export const notFoundValueHandler =
  <T>(callback: () => void, { continueOnErrorCatch }: NonFoundValueHandlerOptions = { continueOnErrorCatch: false }) =>
  (value$: Observable<T>): Observable<NonNullable<T>> =>
    value$.pipe(
      map((value) => {
        if (!value) {
          throw new Error(NOT_FOUND_ERROR_MESSAGE);
        }

        return value;
      }),

      catchError((err) => {
        if (!isNotFoundError(err)) {
          return throwError(() => err);
        }

        callback();
        return continueOnErrorCatch ? throwError(() => err) : EMPTY;
      }),
    );
